import * as React from 'react'


import WpApi from '../wpapi'
import Loader from '../Loader'


import '../css/AanmeldFlow.css'


interface MyProps {
}
type MyState = {
    loading:boolean
    fields:any
    level:string
    level1:any
    message:string
    values:any
}

class AanmeldFlow extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>
    constructor(props: MyProps) {
        super(props)

	this.validate = this.validate.bind(this)
	this.on_submit_click = this.on_submit_click.bind(this)
	this.field_change = this.field_change.bind(this)
	this.state = {
	    loading:false,
	    level:"QL1",
	    level1:{},
	    message:'',
	    values:{},
	    fields:[]
	}
    }
    componentDidMount(){
	let ls_values = localStorage.getItem('values')
	if(ls_values !== null){
	    this.setState({values: JSON.parse(ls_values)})
	}
	this.setState({loading:true})
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return;
	}
	let path = '/aanmeldflow'
	let d = {
	    'x' : 'joe'
	}
	this.fetchPromise = WpApi.do_get(path, d,(r) => {
	    this.setState({ fields: r.fields })
	})
    }
    render_main(){
	return (
	    <div className="content-area main">
		<div className="zw-block">
		    <h1>Ook van het gas af & overstappen op Zonnewarmte Ramplaankwartier?</h1>
		    <img src="./img/startscherm-huisje.png" />
		</div>
	    </div>
	)
    }
    field_change(e:any){
	let trg = e.target
	console.log(`field_change ${trg.name} : ${trg.value}`)
	let k = trg.name
	let v = trg.value
	const values = { ...this.state.values, [k]:v }
	localStorage.setItem('values', JSON.stringify(values));
	this.setState(() => ({ values }))
    }
    render_formfield(tup:any){
	let typeclass : {[k:string]:string}= {
	    'text' : 'text',
	    'select' : 'dropdown'
	}
	let ts :string = tup.type
	let cls = tup.name + " " + typeclass[ts]
	if(tup.type === "message"){
	    if(tup.label.startsWith('introtekst')){
		return (
		    <p className="intro-text"> {tup.message}</p>
		)
	    }
	}
	return (
	    <div className={"hd-input-block simple-view "+cls}>
		<div className="label-container">
		<label> {tup.label} </label>
		{this.render_input(tup)}
	        <span className="msg"></span>
		</div>
	    </div>
	)
    }
    render_input(tup:any){

	if(tup.type === "text"){
	    return (
		<input type="text"
		       name={tup.name}
		       value={this.state.values[tup.name]}
		    onChange={this.field_change}		    
		    placeholder={tup.placeholder}
		    />
	    )
	}
	else if(tup.type === "select") {
	    let opt_list = Object.entries(tup.choices).map(([k,lab]:[string,any],i:number) => (
		<option key={i} value={k}> {lab} </option>
	    ))
	    return (
		<select className="narrow"
			name={tup.name}
			value={this.state.values[tup.name]}
		    onChange={this.field_change}
		    >
		    <option>Selecteer</option>
		    {opt_list}
		</select>
	    )
	}
    }
    
    render_form(){
	if(!this.state.fields.doe_de_check){
	    return null
	}
	return (
	    <div>
		{
		    this.state.fields.doe_de_check.fields.map((field:any)=>
			this.render_formfield(field)
		    )
		}
		<div className="submit-knop" onClick={this.on_submit_click}>
		<a className="button button-next" >Doe "van het gas af" check </a>
		</div>
	    </div>
		
	)
    }
    // zie https://stackoverflow.com/questions/27864951/how-to-access-a-childs-state-in-react
    on_submit_click(e:any){
	console.log('submit')
	this.setState({'message':''})
	let parent = e.target.closest('.zw-block')
	if(this.validate(parent) === true){
	    console.log('do http post')
	    let path = '/aanmeldflow'
	    let d = {
		...this.state.values,
		level: 1
	    }
	    WpApi.do_get(path, d,(r) => {
		console.log(r)
		if(r.status === "ok"){
		    console.log('ok')
		    this.show_level_1(r)
		}else{
		    this.setState({'message' : r.msg})
		}
	    })
	}
    }
    show_level_1(data:any){
	console.log('show L1')
	console.log(data)
	let adr = data.bag.result.straat + ' ' + data.bag.result.huisnummer
	if(data.bag.result.huisletter){
	    adr += ' ' + data.bag.result.huisletter
	}
	this.setState({
	    level: 'RL1',
	    level1: {
		adres: adr,
		bouwlagen: data.bag.bag3d.b3_bouwlagen,
		opp: data.bag.extra.verblijfsobject.oppervlakte,
		gaskosten_var:  data.calc.t0.results.gaskosten_variabele_kosten.default.value,
		gaskosten_vast: data.calc.t0.results.gaskosten_jaarlijks.default.value
	    }
	})
	
    }
    validate(parent:Element){
	let fields = this.state.fields.doe_de_check.fields.filter((x:any) => x.type !== 'message')
	let fieldnames = fields.map((x:any) => x.name)
	let allgood = true
	for(var x of fieldnames){
	    let trg = parent.querySelector(`.hd-input-block.${x}`)
	    if(this.state.values[x] === undefined){
		console.log(`oops ${x} is undef`)
		if(trg!== null){
		    trg.classList.add('warning')
		}
		allgood = false
	    }else{
		if(trg!== null){
		    trg.classList.remove('warning')
		}
	    }
	}
	return allgood
    }
    render_right(){
	if(this.state.level === "QL1"){
	    return (
    		<div className="content-area right">
                    <div className="zw-block zw-block-bg bg-dark">
			<h2>Doe de check</h2>
			{this.render_form()}
		    </div>
		    <div className="message">{this.state.message}</div>
		</div>
	    )
	}else if(this.state.level === "RL1"){
	    let data = {
		'adres' : this.state.level1.adres,
		'gasverbruik': this.state.values.gasverbruik,
		'aantal_bewoners' : this.state.values.aantal_bewoners,
		'bag:oppervlak' : this.state.level1.opp,
		'bag:bouwlagen' : this.state.level1.bouwlagen,
		'calc:t0:gaskosten_variabel': this.state.level1.gaskosten_var,
		'calc:t0:gaskosten_vast': this.state.level1.gaskosten_vast
	    }
	    return (
    	    <div className="content-area right">
		<table>
		    {Object.entries(data).map(([k,v] : [string,string]) => 
			(<tr><td>{k}</td> <td> {v} </td> </tr>)
		    )}
		</table>
	    </div>
	    )
	}
    }

    render(){
	return (
	    <div id="main" className="main-regular">
		<div className="layout-main layout-main-with-right">
		    {this.render_main()}
		    {this.render_right()}
		</div>
	    </div>
	)
    }
}

export default AanmeldFlow
