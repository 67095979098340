import * as React from 'react'

import $ from 'jquery'

import './css/HdaList.css'
import './css/Expanders.css'
import './modals/css/Popup.css'
import HdaSection from './HdaSection'

import VoteGridModal from './modals/VoteGridModal'
import HistoryModal from './modals/HistoryModal'
import InfoModal from './modals/InfoModal'
import HdBaseInput from './HdBaseInput'

import WpApi from './wpapi'
import Loader from './Loader'


interface MyProps {
    post_id: string,
    user:any,
    filter:any,
    view:string,
    blocktype:string
    tech?:string
}



type MyState = {
    section_rows: Array<any>,
    msg?: string,
    loading:boolean,
    show_base_input:boolean
    options:any,
    permissions:any,
    need_recalc:boolean
};

interface cb_after_vote_event {
    (r:any): void;
}



class HdBlockList extends React.PureComponent<MyProps,MyState> {

    private current_row_key = "-";

    private vote_popup =  React.createRef<VoteGridModal>();
    private history_popup =  React.createRef<HistoryModal>();
    private info_popup =  React.createRef<InfoModal>();


    private callback_after_vote_event :cb_after_vote_event = (x)=>{ console.log("dummy")}
    private fetchPromise ?: Promise<void>
    
    constructor(props: MyProps) {
        super(props)
        this.handleClick = this.handleClick.bind(this)
	this.open_popup = this.open_popup.bind(this)
	this.after_vote_event = this.after_vote_event.bind(this)
	
	this.state = {
	    section_rows: [],
	    loading:false,
	    show_base_input:false,
	    options: {},
	    permissions:{},
	    need_recalc:false,
	    msg:undefined
	}
    }

    public handleClick(e:any){
	this.init()
    }
    get_search_params(){
	let p = new URLSearchParams(window.location.search)
	let attr_id : Number = -1
	if(p.get('attr') !== null){
	    attr_id = Number(p.get('attr'))
	}
	return {
	    acfkey: p.get('acfkey'),
	    level1: p.get('level1'),
	    block: p.get('block'),
	    mtype: p.get('mtype'),
	    level2: p.get('level2'),
	    attr: attr_id,
	    bd: p.get('bd')
	}
    }
   
    scroll_position(){
	let p = this.get_search_params()
	let key = p.level1
	if(key === null){
	    return
	}
	console.log(`scroll to key:${key}`)
	let q = `div[acf-key="${key}"]`
	console.log(q)

	let elm = document.querySelector(q)
	console.log(elm)
	if(elm instanceof HTMLElement){
	    console.log( `top: ${elm.offsetTop}`)
	    window.scroll({
		top: elm.offsetTop,
		left: 0,
		behavior: "smooth",
	    });
	}
    }
    public init(){
	this.setState({loading:true})
	if(this.props.post_id === "-"){
	    let r :Array<any> = []
	    //r = get_dummy_data()
	    console.log(r)
	    this.setState({
		section_rows : r,
		loading:false
	    })
	}else{
	    if (this.fetchPromise) {
		console.log(' already mounted previously')
		return;
	    }
	    let d = {
		post_id:this.props.post_id,
		view:this.props.view,
		tech: this.props.tech === undefined ? '' : this.props.tech
	    }
	    let path = '/hd-actions'
	    let calc_views = ["rm_basis", "rm_tech" ]
	    if(calc_views.includes(this.props.view)){
		path = '/calc'
	    }
	    this.fetchPromise = WpApi.do_get(path, d,(r) => {
		if(r.code === "rest_forbidden"){
		    this.setState({msg: 'No access. '+r.message})
		}else{
		    if(r.status == 'warning'){
			this.setState({'msg':r.msg})
			if(calc_views.includes(this.props.view)){
			    this.setState({
				show_base_input:true,
				loading:false
			    })
			}
		    }else{
			setTimeout(()=>{this.scroll_position()  },500)
			this.setState({
			    section_rows  : r.sections,
			    options: this.get_options(r.options),
			    permissions: r.permissions,
			    need_recalc: r.need_recalc,
			    loading:false
			})
		    }
		}
	    })
	}
    }
    get_options(tup:any){
	tup.config = this.get_search_params()
	tup.config.blocktype = this.props.blocktype
	tup.config.filter = this.props.filter
	return tup
    }
    componentDidMount(){
	console.log('did mount')
	this.init()
    }
    // signal is received from Section
    public open_popup(e:any, cb:(r:any)=>void){
	console.log(`open-popup cmd: ${e.cmd}`)
	let votetypes = [
	    'quotations-comment-vote',
	    'quotation-status-vote',
	    'choice-vote',
	    'advise-comment-vote',
	    'inputs-comment-vote',
	    'advise-vote',
	    'comment-vote',
	    'number-vote'
	]
	if(votetypes.includes(e.cmd)){
	    this.vote_popup.current!.init(e)
	}else if(e.cmd === 'quotation-history'){
	    this.history_popup.current!.init(e)
	}else if(e.cmd === 'open-history'){
	    this.history_popup.current!.init(e)
	}else if(e.cmd === 'open-info'){
	    this.info_popup.current!.init(e.txt)
	}
	// point callback to the calling Section
	this.callback_after_vote_event = cb;
    }

    /*
     *  user clicked in VoteGrid, send post to api
     */
    public after_vote_event(v:any){
	console.log(`got vote-event kind ${v.kind}`)
	console.log(v)

	let d :{[name:string]:string}= {
	    post_id: this.props.post_id
	}
	if(v.tup !== undefined){
	    if(v.tup.select !== undefined){
		d.acf_key = v.tup.select.key
	    }
	}
	if(v.acf_key !== undefined){
	    d.acf_key = v.acf_key
	}
	if(v.votetype !== undefined){
	    d.votetype = v.votetype
	}
	if(v.aspect !== undefined){
	    d.aspect = v.aspect
	}
	if(v.bouwdeel !==undefined && v.bouwdeel !== null){
	    d.bouwdeel = v.bouwdeel
	}
	if(v.kind === 'chosen_option'){
	    d.value = v.value
	    d.is_final_field = v.is_final_field
	    d.trigger_recalc = v.trigger_recalc
	    if(v.on_attr_id !== undefined){
		d.on_attr_id = v.on_attr_id
	    }
	    d.level2 = v.level2

	}else if(v.kind === 'remove_vote'){
	    d.attr_id = v.attr_id
	    d.votetype = 'remove_vote'
	    d.is_final_field = v.is_final_field	    
	}else if(v.kind === 'add_comment'){
	    d.votetype = 'add_comment'
	    d.value = v.txt
	    d.level2 = v.level2
	    if(v.on_attr_id !== undefined){
		d.on_attr_id = v.on_attr_id
	    }
	}else if(v.kind === 'add_advise'){
	    d.votetype = 'add_advise'
	    d.level2 = v.level2
	    d.value = v.txt
	}else if(v.kind === 'update_advise'){
	    d.attr_id =  v.attr.attr_id
	    d.value = v.value
	}else if(v.kind === 'update_comment'){
	    d.attr_id =  v.attr.attr_id
	    d.value = v.comment
	    if(v.on_attr_id !== undefined){
		d.on_attr_id = v.on_attr_id
	    }
	}else if(v.kind === 'add_number'){
	    d.votetype = 'add_number'
	    d.value = v.val
	}else{
	    console.log("unknown v.kind")
	    return
	}
	console.log(d)
	this.callback_after_vote_event({ hook: 'before_api_call'})

	WpApi.do_post('/hd-attr',d, (r) => {
	    this.callback_after_vote_event(r)
	})
	
    }
    show_step(tup:any){
	if(this.props.view === "buildingmeasures"){
	    return false
	}else{
	    if(tup.cftype === "hda-bouwdelen-stap"){
		return false
	    }
	}
	return true
    }

    show_row(row:any){
	let clslist = row.classes === undefined ? [ row.class ] : row.classes

	if(this.props.view === "buildingmeasures"){
	    return clslist.includes('buildingmeasures')
		|| clslist.includes('l2potmeasures')
	    	|| (row.cftype === "header"  && ! row.label.startsWith("D"))

	}
	else if(this.props.view === "actionlist"){
	    return clslist.includes('buildingpart')
		|| clslist.includes('actions')
		|| row.cftype === "header"
	}
	else if(this.props.view === "collab_input"){
	    return clslist.includes( 'input')
	}
	else if(this.props.view === "combi"){
	    return clslist.includes('buildingpart')
	}
	else if(this.props.view === "hdquotations"){
	    return clslist.includes('l2quotations')
		|| row.cftype === "header"
		|| row.name === "invoer_en_berekeningen"
	}
	else if(this.props.view === "rm_basis"){
	    return clslist.includes('rm_v2')
	}
	else if(this.props.view === "rm_tech"){
	    return clslist.includes('rm_v2')
	}
	return true
    }
    jump_to(row:any){
	console.log('jump-to')
	console.log(row)
	let key = row.key
	console.log(`scroll to ${key}`)
	let q = `div[acf-key="${key}"]`
	console.log(q)
	let elm = document.querySelector(q)
	console.log(elm)
	if(elm instanceof HTMLElement){
	    elm.classList.add("highlight")
	    window.scroll({
		top: elm.offsetTop,
		left: 0,
		behavior: "smooth",
	    });
	}
    }
    render_toc(){
	return this.state.section_rows.map((row, i) => {
	    var label = row.label
	    if(this.props.view === "actionlist"){
		if(row.cft_identifier){
		    label = row.cft_identifier + '. ' + row.label
		}
	    }
	    let cls = row.cftype === 'header' ? 'header-main' : 'header-default'
	    return this.show_row(row) ? (
		<div key={i} className={cls}>
		    <div  className="item level-1" onClick={()=>this.jump_to(row)}>{label}</div>
		</div>
	    ) : null
	})
    }
    render_sections(){
	return this.state.section_rows.map((row, i) => {
	    return this.show_row(row) ? this.render_row(row,i) : null
	})
    }
    render_row(row:any, i:number){
	// DEBUG: show only 1 row
	let debug_row = "opwek"
	// let debug_row = "isolatie_dak_vliering"
	// let debug_row = "afronding"
	//if(row.name !== debug_row){ return }

	if(this.props.filter.level1 !== undefined){
	    if(!this.props.filter.level1.includes(row.key)) {return null}
	}

	return row.cftype === "header" ? (
	    <div acf-key={row.key} className="cluster-level0-header" key={i}>{row.label}</div>
	)  : (
		<HdaSection
		    user={this.props.user}
		    view={this.props.view}
		    permissions={this.state.permissions}
		    options={this.state.options} 
		    cb_parent={this.open_popup}
		    key={i} post_id={this.props.post_id} tup={row} />
	)
    }
    handle_click_expand_all(e:any){
	$('.expander-buttons .cf-button').removeClass('pressed');
	$(e.target).addClass('pressed')
	$('.with-expand.collapsed').removeClass('collapsed');
    }
    handle_click_unexpand_all(e:any){
	$('.expander-buttons .cf-button').removeClass('pressed');
	$(e.target).addClass('pressed')
	$('.with-expand').addClass('collapsed');
    }
    render_toc_div(){
	<div className="toc">
	    <h3>Inhoudsopgave</h3>
	    this.render_toc()	     
	</div>
    }
    render_current_techniek(){
	if(this.props.view === "rm_v2"){return null}
	if(this.state.options.techniek === undefined){return null}
	return (
	    <div className="current-techniek">
		<h3>Techniek: {this.state.options.techniek.label} </h3>
		</div>
	)
    }
    render_base_input(){
	if(this.state.show_base_input){
	    return (<HdBaseInput post_id={this.props.post_id} />  )
	}
    }
    public render(){

	let show_expander_buttons =  this.state.loading ? false : true

	if(this.props.blocktype === "simple"){
	    show_expander_buttons = false
	}
	
	return (
	    <div className="hd-actions-react">
		{this.state.loading ? <Loader /> : null}


		<div className="msg">{this.state.msg} </div>
		{this.render_base_input()}
		<div className={"sections view-"+this.props.view}>
		    {show_expander_buttons ? (
			<div className="expander-buttons">
			    <span className="cf-button" onClick={this.handle_click_expand_all}>Alles openklappen</span>
			    <span className="cf-button pressed" onClick={this.handle_click_unexpand_all}>Alles dichtklappen</span>
			    
			    
			</div>
		    ) : null}
	    {this.render_current_techniek()}
	    <div className="sections-list">
	        {this.render_sections()}
	    </div>
		</div>
		<VoteGridModal ref={this.vote_popup} cb_parent={this.after_vote_event} post_id={this.props.post_id} />
		<HistoryModal ref={this.history_popup}  post_id={this.props.post_id} />
		<InfoModal ref={this.info_popup} />
	    </div>
	);
    }
}
export default HdBlockList;
