import * as React from 'react'

import WpApi from './wpapi'
import './css/HdaList.css'

import './css/RmGlobals.css'
import RmGlobalField from './RmGlobalField'

interface MyProps {

}
type MyState = {
    sections:Array<any>
    msg:string
}

class RmGlobals extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>
	
    constructor(props: MyProps) {
        super(props)
	this.state = {
	    msg: '',
	    sections: []
	}
    }
    init(){
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return;
	}
	let d = {
	    view: 'rm_globals'
	}
	let path = '/calc'

	this.fetchPromise = WpApi.do_get(path, d,(r:any) => {
	    if(r.code === "rest_forbidden"){
		this.setState({msg: 'No access. '+r.message})
	    }else{
		this.setState({
		    sections: r.sections
		})
	    }
	})
    }
    componentDidMount(){
	console.log('did mount')
	this.init()
    }
    render_fields(fields:Array<any>){
	return (
	    <div className="steps">
		{fields.map((e:any) => <RmGlobalField tup={e} />)}
	    </div>
	)
    }
    render_sect(tup:any){
	return (
	    <div className="section globals cluster l1-cluster">
		<div className="header">
		    <div className="label">{tup.label}</div>
		</div>
		<div className="body">
		    {this.render_fields(tup.steps)}
		</div>
	    </div>
	)
    }
    render_sections(){
	return (
	    <div className="sections-list">
		{this.state.sections.map((e:any) => this.render_sect(e))}
	    </div>
	)
    }
    render(){
	return (
	    <div className="hd-actions-react">	    
		<div className="rm-globals sections">
		<h3>Algemene rekenvariabelen, HD-onafhankelijk</h3>
		    {this.render_sections()}
		</div>
	    </div>
	)
    }
}

export default RmGlobals
