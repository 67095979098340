import * as React from 'react';

import './css/RmStep.css'
import Expander_helper from './Expander_helper'
import * as ReactDOMServer from 'react-dom/server';

interface MyProps {
    tup:any,
    substeps?:Array<any>,
    options:any,
    cb_parent:   (e: any, cb: (r: any) => void) => void
}
type MyState = {
    is_loading:boolean
    value:string
}

class RmStep extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)
	this.on_input_change = this.on_input_change.bind(this)
	this.click_edit_btn = this.click_edit_btn.bind(this)
	this.state = {
	    is_loading:false,
	    value: ''
	}
    }
    componentDidMount(){
	let v = this.props.tup.winner
	
	if(v === undefined || v === null){
	    return
	}
	this.setState({value:v})
    }
    click_edit_btn(e:any){
	console.log('test')
	let lbl = this.props.tup.label
	var d :{[k:string]:any}= {
	    acfkey: this.props.tup.key,
	    label:lbl,
	    top3: [],
	    cmd: "number-vote",
	    aspect: "huidig",
	    trigger_recalc: true,
	}
	if(this.props.tup.cft_identifier.includes("option-input")){
	    d['cmd'] = 'choice-vote'
	    if(this.props.tup.select_key === undefined){
		d['acf_key_select'] = this.props.tup.key
	    }else{
		d['acf_key_select'] = this.props.tup.select_key
	    }
	    d['acf_key_step'] = this.props.tup.key
	}
	this.props.cb_parent(d,(r:any)=>{
	    if(r.hook === 'before_api_call'){
		// 1. the popup is closed but API-call is still running
		this.setState({is_loading:true})
	    }else{
		console.log(r)
		if(r.new_state){
		    this.setState({value:r.winner_txt})
		}
	    }
	})
    }
    on_input_change(e:any){
	if(this.props.tup.cft_identifier === "num-input"){
	    let v = e.target.value
	    if(!isNaN(Number(v))){
		this.setState({value: v})
	    }
	}
    }
    show_option_text(){
	let tup = this.props.tup
	if(tup.calculated === undefined){
	    return (<div> no calc </div>)
	}
	let args = this.props.tup.calculated.level1.default.args
	console.log(args)
	let keys = Object.keys(args)
	if(keys.length > 0){
	    let k0 = keys[0]
	    let v0 = args[k0]
	    console.log(v0)
	    let v0s = v0.ret.string
	    
	    console.log(`v0s : ${v0s}`)
	    if(v0.choices !== undefined){
		let lab = v0.choices[v0s]
		return (<div> {lab} </div>)
	    }
	    return (<div>  {v0s} </div>)
	}

	//let lab = arg0.choices[choice]
	return (
	    <div> - undef level1 args </div>
	)
    }
    render_body(){
	let tup = this.props.tup
	let part1 = (<div></div>)
	let div_input = (<div></div>)

	if(tup.cft_identifier.includes("num-input") || tup.cft_identifier.includes("option-input")){
	    let txt = tup.cft_identifier.includes("default") ? "Of je eigen keuze" : "Keuze"
	    let val = this.state.value
	    if(tup.cft_identifier.includes("option-input")){
		val = tup.choices[val]
	    }
	    if(this.props.tup.append === '%'){
		val = (Number(val)*100).toFixed(0)
	    }
	    div_input = (
		<div className="num-input"> <span>{txt}: </span>
		    <span>{val} {this.props.tup.append}</span>
		    <div className="cf-button" onClick={this.click_edit_btn}>Wijzig</div>
		    
		</div>
	    )
	}

	if(tup.classes.includes('postmeta')){
	    part1 = (<div>{tup.value}</div>)
	}
	if(tup.cft_identifier.includes("calc-default")){
	    part1 = this.render_calc_single(tup)
	}
	else if(tup.cft_identifier === "calculation"){
	    if(tup.classes.includes('show-option-text')){
		return this.show_option_text()
	    }
	    if(tup.classes.includes('show-left')){
		return this.render_calc_single(tup)
	    }
	    if(tup.calculated === undefined){
		return (<div>ERROR calculated undefined </div>)
	    }
	    let cols = tup.calculated.level1
	    if(cols === undefined){
		cols = tup.calculated
	    }
	    return (
		<div className="logic-with-cols level-1">
		    {this.render_logic()}
		{this.render_calculation_columns(tup,cols)}
		</div>
	    )
	}

	if(tup.cft_identifier === "global-num" || tup.cft_identifier.includes("global-default")){
	    let v = Number(tup.value).toFixed(0)

	    let append = tup.append === undefined ? "" : tup.append
	    if(tup.classes.includes("percentage")){
		append = '%'
	    }
	    if(tup.append === '%' || tup.classes.includes("percentage")){
		v = (Number(tup.value)*100).toFixed(0)
	    }else{
		let precision = this.get_precision()
		v = Number(tup.value).toFixed(precision).replace('.',',')
	    }
	    let pre = tup.prepend === undefined ? "" : tup.prepend
	    if(pre === '€' && tup.value < 0){
		// swap - and €
		v = v.substr(1)
		pre = '-€'
	    }
	    
	    let nvt = ""
	    let nvt_class = ""
	    if(this.props.tup.classes.includes('tech-setting')){
		if(! this.props.tup.techniek){
		    nvt = "n.v.t."
		    nvt_class = "disabled"
		}
	    }
	    

	    part1  =  (
		<div className={nvt_class} >Brongetal : {pre} {v} {append} {nvt}</div>
	    )
	}
	return (
	    <div>
		{this.render_logic()}
		{this.render_techsetting()}
	        {part1} {div_input}
	    </div>
	)
    }
    render_logic(){
	let calc = this.props.tup.calculated
	if(calc === undefined
	    || calc.level1 === undefined
	    || calc.level1.logic === undefined
	  ){
	    return (
		<div className="calc-row-wrapper logic">
		    </div>
	    )
	}
	    
	let ctup = calc.level1.logic
	return (
	    <div className="calc-row-wrapper logic">
		{this.render_calc('Is actief?',ctup,"",1,"logic")}
	    	<div className="traces">
		{this.render_calc_trace(ctup,"",1,"logic")}
	    </div>
	    </div>
	)

    }
    get_precision(){
	let tup = this.props.tup
	for(var cl of tup.classes){
	    if(cl.includes("precision")){
		let x = cl.split('-')
		return Number(x[1])
	    }
	}
	return 0
    }
    render_techsetting(){
	if(this.props.tup.classes.includes('tech-setting')){
	    let v = this.props.tup.techniek_str
	    return (
		<div className="tech-setting">{v}</div>
	    )
	}
    }
    render_calc_single(tup:any){
	if(tup.calculated === undefined){
	    return (<div> error calculated is undefined </div>)
	}
	let calculated = tup.calculated.level1
	if(calculated === undefined){ return (<div></div>) }

	let append = tup.append
	// calculation columns other than 'logic'
	let calc_keys = Object.keys(calculated).filter((x) => x !== 'logic')
	let multiple = calc_keys.length > 1
	if(multiple){
	    return (<div> ERROR expect no columns in calc-single </div>)
	}
	let cname = calc_keys[0]
	let ctup = calculated[cname]
	return (
	    <div className="calc-row-wrapper logic">
		{this.render_logic()}
		<div className="calc-value">{this.render_calc('Berekend:',ctup,append,1,"x")} </div>
	    	<div className="traces">
		{this.render_calc_trace(ctup,"",1,"x")}
	    </div>
		</div>
	    
	)
    }
    click_explain(e:any,cname:string){
	console.log(` explain cname ${cname} `)
	let was_selected = e.target.classList.contains('selected')
	let parent = e.target.closest('.calc-row-wrapper')
	if(parent === null){return}
	// remove class selected for all values
	parent.querySelectorAll('.value').forEach((x:Element)=>x.classList.remove('selected'))
	// remove class show for all trace-bodies
	parent.querySelectorAll('.traces .trace').forEach((x:Element)=>x.classList.remove('show'))
	let trg = parent.querySelector(`.traces .trace.${cname}`)
	
	if(was_selected){
	    e.target.classList.remove('selected')
	    trg.classList.remove('show')
	}else{
	    e.target.classList.add('selected')
	    trg.classList.add('show')
	}
	
    }
    render_calc_trace(ctup:any,append:string,i:number,cname:string){


	let val = this.rounded_value(ctup,append,cname)

	if(ctup.args === undefined){ return null}
	return (
	    <div key={i} className={"trace "+cname}>
	    <div className="header"><div className="label">Rekenvariabelen:</div></div>	    

	    {Object.entries(ctup.args).map(([k,tup]:[string,any],i:number) => {
		let v = tup.ret.value
		let v2 = v
		let slug = tup.ref
		if(tup.ret.type !== "text" && v!==null){
		    v2 = v.toFixed(2).replace('.',',')
		}
		
		let slug0 = slug.split(':')[0]
		let col = (<div></div>)
		if(slug.split(':').length > 1){
		    // column defined
		    let colkey = slug.split(':')[1]
		    if(colkey === 'tech_setting'){
			col = (<div className="col"> Is actief? </div>)
		    }else{
			let col_label = 
			    colkey.length === 2 ?
			    this.props.options.columnsets.scenarios_abc[colkey] : colkey
			let col_txt = col_label == 'logic' ? 'Is-actief?' : 'Scenario: '+col_label

			col = (<div className="col">( {col_txt} )</div>)
		    }
		}


		let lab = this.props.options.findex[slug0]

		if(tup.ret.type === 'option'){
		    let opt_slug = tup.ret.string
		    let opt_label = tup.choices[opt_slug]
		    return (
			<div className="arg option" key={i}>{k}:   {lab}  : {opt_label}
			    <div className="value">= {v2}</div>
			    </div>
		    )
		    }

		return (
		    <div className="arg" key={i}>{k}:   {lab} {col}
			<div className="value">= {v2}</div>
			</div>
		)
	    })}
	    	<div className="header"><div className="label">Formule:</div></div>
		<div>{ctup.trace1} = </div>
		<div>{ctup.trace2} = {val}</div>
	</div>
	)
    }
    rounded_value(ctup:any,append:string,cname:string){
	
	if(this.props.tup.classes !== undefined && this.props.tup.classes.includes('text-value')){
	    return ctup.value
	}
	let precision = cname === "logic" ? 0 : this.get_precision()
	if(append === '%'){
	    return Number(100* ctup.value).toFixed(precision) + '%'
	}else{
	    return Number(ctup.value).toFixed(precision).replace('.',',')	    
	}
    }
    render_calc(txt:string,ctup:any,append:string,i:number,cname:string){
	let exp = new Expander_helper();
	let val = this.rounded_value(ctup,append,cname)
	let pre = this.props.tup.prepend
	
	if(cname === "logic"){
	    append = ""
	    pre = ""
	}
	if(append === "%"){
	    append = ""
	}
	if(pre === '€' && ctup.value < 0){
	    // swap - and €
	    val = val.substr(1)
	    pre = '-€'
	}
	var default_hide_mode = false

	for(var c of this.props.tup.classes){
	    if(c.startsWith("show-col")){
		default_hide_mode = true
	    }
	}
	let classes  = "calculation with-expand collapsed col-"+cname
	if(default_hide_mode){
	    let cl0 = "show-col-" + cname
	    if(! this.props.tup.classes.includes(cl0)){
		classes += " hidden"
	    }
	}else{
	    let cl0 = "hide-col-" + cname

	    if(this.props.tup.classes !== undefined && this.props.tup.classes.includes(cl0)){
		classes += " hidden"
	    }
	}



	return (
	    <div className={classes} key={i}>
		<div className={"value "+cname} onClick={(e)=>{this.click_explain(e,cname)}}>{txt} {pre} {val} {append} </div>
	    </div>
	)
    }
    render_calculation_columns(tup:any, calculated:any){
	//let calculated = tup.calculated.level1
	if(calculated === undefined){return null}
	let append = tup.append
	let size = Object.entries(calculated).length
	let multiple = size > 1
	let colmap : {[name:string]:string}= {}
	for(var x of Object.entries(this.props.options.columnsets)){
	    let [k,choices]:[string,any] = x
	    if(tup.classes.includes(k)){
		console.log(`yep match ${k}`)
		colmap = choices
		size = Object.entries(choices).length
	    }
	}
	
	let gridsize = "grid-"+size
	return (
	    <div className="calc-row-wrapper">
		{this.render_techsetting()}
	    <div className={"calculations "+gridsize}>
		{Object.entries(calculated).map(([cname,ctup]:[string,any],i:number) => {
		    if(ctup.type === "skipped-col"){
			return (<div className="skip"></div>)
		    }
		    let collabel = colmap[cname]
		    //let txt = multiple ? (collabel === undefined ? cname  : collabel): 'Berekend'
		    let txt = ""
		    if(cname === "logic"){
			return null
		    }
		    return this.render_calc(txt,ctup,append,i,cname)
		})}
	    </div>
		<div className="traces">
		{Object.entries(calculated).map(([cname,ctup]:[string,any],i:number) => {
		    if(ctup.type === "skipped-col"){ return null }
		    return this.render_calc_trace(ctup,append,i,cname)
		})}
		    </div>
		</div>
	)
    }
    render_substeps(){
	if(this.props.substeps === undefined){ return null }
	return this.props.substeps.map((tup:any,i:number) => {
	    let ival = tup.value
	    if(tup.append === '%'){
		ival *= 100
	    }
	    let precision = 0
	    let sval = Number(ival).toFixed(precision).replace('.',',')
	    return (
		<div key={i} className="substep">
		    {tup.label}: {tup.prepend} {sval} {tup.append}
		</div>
	    )
	})
    }
    render_l2_calc(){
	let tup = this.props.tup
	if(tup.calculated === undefined){
	    return null
	}
	if(tup.calculated.level2 === undefined){
	    return null
	}
	let l2 = tup.calculated.level2
	let l2keys = Object.keys(l2)
	return l2keys.map((k:string,i:number) => {
	    let lab = this.props.options.l2names[k]
	    if( lab === undefined){
		lab = k
	    }
	    return (
		<div key={i} className="level2-calc">
		    <div className="label">{lab}</div>
		    {this.render_calculation_columns(tup,l2[k])}	        
		</div>
	    )
	})
    }
    render(){
	let label_txt = this.props.tup.label
	return (
	    <div className="rm-step step">
	    	<div className="header" >
		    <div className="label">{label_txt}</div>
		</div>
		<div className="body">
		{this.render_body()}
	    {this.render_substeps()}
	    {this.render_l2_calc()}
		</div>
	    </div>
	)
    }
}

export default RmStep
