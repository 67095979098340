import * as React from 'react'
interface MyProps {
    options:any
    mode:string
    view:string
}
type MyState = {}

class Rm20Headers extends React.PureComponent<MyProps,MyState> {

    render_abc(){
	if(this.props.options.skip_cols.length > 0){
	    // only 1 item
	    let only = this.props.options.scenario.substr(0,2)
	    return (
		<div className="header-row grid-3 only-one">
		    {Object.entries(this.props.options.columnsets.scenarios_abc).map(([k,lab]:[string,any],i:number) => {
			if(k === only){
			    return (<div key={i}>{lab}</div>)
			}else{
			    return (<div className="disabled">{lab}</div>)
			}
		    })}
		</div>
	    )
	}
	return (
	    <div className="header-row grid-3">
		{Object.entries(this.props.options.columnsets.scenarios_abc).map(([k,lab]:[string,any],i:number) => {
		    return (<div key={i}>{lab}</div>)
		})}
	    </div>
	)
    }
    render_a1c2(){
	if(this.props.options.skip_cols.length > 0){
	    // skip some 

	    return (
		<div className="header-row grid-6">
		    {Object.entries(this.props.options.columnsets.scenarios_a1c2).map(([k,lab]:[string,any],i:number) => {
			if(this.props.options.skip_cols.includes(k)){
			    return (<div key={i} className="disabled">{lab}</div>)
			}
			return (<div key={i}>{lab}</div>)
		    })}
		</div>
	    )
	}else{
	    return (
		<div className="header-row grid-6">
		    {Object.entries(this.props.options.columnsets.scenarios_a1c2).map(([k,lab]:[string,any],i:number) => {
			return (<div key={i}>{lab}</div>)
		    })}
		</div>
	    )
	}
    }
    render(){
	return (
	    <div className="rm20-header">
		{this.render_abc()}
	    	{this.props.mode === 'a1c2' ? this.render_a1c2() : null}
	    </div>
	)
    }
}

export default Rm20Headers
